.herocontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.videosection {
  position: relative;
}

.videoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.h1hero1 {
  color: #ffffff; /* Cambia el color del texto a blanco */
  font-size: 2.5rem; /* Cambia el tamaño del texto */
}
.video1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .herocontainer {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .textContainer {
    width: 80%;
  }
}

/* Ajuste para eliminar el espacio en blanco entre los videos */
@media screen and (min-width: 768px) {
  .herocontainer {
    grid-column-gap: 0; /* Elimina el margen entre las columnas */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');




.container1 {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../imagenes/bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 20px;
    gap: 10px;
    width: 100%;
}

.container1 > * {
    flex: 1;
}


.box-info {
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 80px;
}

.box-info > h1 {
    text-align: left;
    letter-spacing: 5px;
}
.h1contact {
    text-align: left;
    letter-spacing: 5px;
    font-size: 36px;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Aumentado el espacio entre los elementos */
}

.data > p {
    font-size: 1.2rem; /* Aumentado el tamaño de fuente */
}

.data > p > i {
    color:#00A9BA;
    margin-right: 10px;
    font-size:25px;
}



.links {
    display:flex;
    align-items: center; /* Center items vertically */
    gap:15px;
}

.links > a {
    text-decoration: none;
    width: 40px;
    height: 40px;
    background: #00A9BA;
    text-align: center;
    display: flex; /* Use flexbox to center the icon */
    align-items: center; /* Center icon vertically */
    justify-content: center; /* Center icon horizontally */
    transition: 0.1s;
}

.links > a > i {
    color: #fff;
    font-size: 20px;
}

form {
    display:flex;
    flex-direction: column;
    text-align: center;
    gap:15px;
}

.input-box {
    position:relative;
}

.input-box > input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    outline:none;
    background: rgba(255 255 255 / .1);
    border:3px solid transparent;
    letter-spacing: 1px;
    transition:.3s;
    color:#fff;

}

.input-box > input::placeholder,
.input-box > textarea::placeholder {
    color:#a3a3a3;

}

.input-box > input:focus::placeholder,
.input-box > textarea:focus::placeholder {
    color:transparent;
}

.input-box > input:focus,
.input-box > textarea:focus {
    border-bottom:3px solid #00A9BA;
    animation: shake .2s;
}

.input-box > textarea {
    width: 100%;
    height: 130px;
    padding: 10px;
    background: rgba(255 255 255 / .1);
    border:3px solid transparent;
    letter-spacing: 1px;
    outline: none;
    transition:.3s;
    color:#fff;
    letter-spacing: 1.5px;
}


.input-box > i {
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    right: 10px;
    color:rgba(255 255 255 / .3);
    transition: .3s;
}

.input-box > input:focus ~ i {
    color:#00A9BA;
}

form > button {
    width: 100%;
    padding: 10px;
    outline: none;
    background: #00A9BA;
    color:#fff;
    border:none;
    transition: .1s;
    cursor: pointer;
    font-size: 1rem;
}

form > button:hover,
.links > a:hover {
    background: rgb(255, 255, 255);
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Fondo blanco semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


@keyframes shake {
    0%, 100% {transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
    20%, 40%, 60%, 80% {transform: translateX(10px);}
 }


@media screen and (max-width:600px) {
    .container {
        width: 95%;
        display: flex;
        flex-direction: column;
        gap:20px;
    }
    form {
        width: 100%;
    }
    .container1 {
        flex-direction: column;
        align-items: center;
    }

    .box-info {
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-left: 0;
    }

    .h1contact {
        text-align: left;
        letter-spacing: 5px;
        font-size: 30px;
    }
}


@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

*::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento */
}

*::-webkit-scrollbar-thumb {
  background-color: #00A9BA; /* Color del "pulgar" de la barra de desplazamiento */
  border-radius: 6px; /* Borde redondeado del pulgar */
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo de la barra de desplazamiento */
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}



.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.cards {
  padding: 4rem;
  background: #fff;
}
.cards__paragraphs {
  text-align: justify;
  margin: 0 auto;
  max-width: 600px;
  font-size: 20px;
}


.h1cards {
  text-align: center;
  /* max-width: 50%; */
  color: #01B4BB;
  font-size: 70px;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}
.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


/* ------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: none;
  border: none;
}

.modal__img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #ffffff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  background-color: #01B4BB; /* Agregar esta línea para establecer el fondo blanco */
  padding: 10px; /* Añadir relleno para que el fondo sea visible alrededor del texto */
  border-radius: 5px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Alinea el texto al centro */
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-around; /* Espacia los elementos del footer */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en dispositivos pequeños */
}


.li-footer{

  margin-bottom: 8px;
  color: white;
  cursor: pointer;
}
.p-correo {
  color:white;
  cursor: pointer;
}

.youtube-icon {
  color: red; /* Cambia el color del icono de YouTube a rojo */
}

.linkedin-icon {
  color: blue; /* Cambia el color del icono de LinkedIn a azul */
}

.footer-link-wrapper {
  display: flex;
  flex-direction: column; /* Apila los elementos del footer en columnas */
  align-items: center; /* Alinea los elementos al centro */
  margin: 10px;
  text-align: center; /* Alinea el texto al centro */
  width: 220px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 8px;
  color: #01B4BB; /* Color del título */
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.social-media-wrap {
  display: flex;
  justify-content: space-around; /* Espacia los elementos del footer */
  align-items: center; /* Alinea los elementos al centro */
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-around; /* Espacia los iconos sociales */
  align-items: center; /* Alinea los iconos al centro */
  width: 240px;
}

.social-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

/* Estilo específico para los iconos de LinkedIn y YouTube */
.social-icon-link {
  font-size: 1.7rem; /* Tamaño de los iconos */
  margin-bottom: 1rem; /* Espaciado entre los iconos y el texto */
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column; /* Apila los elementos del footer en columnas */
    width: 100%; /* Ajusta el ancho al 100% */
    margin-bottom: 20px; /* Agrega espaciado entre los elementos */
  }

  .social-media-wrap {
    flex-direction: column; /* Apila los iconos sociales en columnas */
  }
}

.navbar {
  background: #F6F7FB;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #F6F7FB;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo-image {
  max-width: 190px; /* Adjust the value according to your preference */
  height: auto;
  margin-top: 10px; /* Adjust the top margin to position the logo within the navbar */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;

  color: #01B4BB;
}

.nav-links {
  color: #01B4BB;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #01B4BB;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #F6F7FB;
}

.nav-links-mobile {
  display: none;
  color: #01B4BB; /* o el color que prefieras */

}

.menu-icon {
  display: none;
}

.dropdown-menu {
  background: #F6F7FB;
  position: absolute;
  top: 80px; /* Ajusta la posición según tu preferencia */
  left: 72.5%; /* Centra el menú bajo el botón */
  width: 160px;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-menu li {
  height: 40px;
  display: flex;
  align-items: center;
}

.dropdown-menu a {
  color: #01B4BB;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown-menu a:hover {
  background-color: #01B4BB; /* o el color que prefieras */
  color: #F6F7FB; /* o el color que prefieras */
}
.nav-menu.active {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
}

@media screen and (max-width: 960px) {

  .nav-links.dropdown-active {
    background-color: #000000 !important; /* Agregar !important para asegurar la prioridad */

    color: #000000 !important;
    border-radius: 4px; /* Ajusta el radio del borde según tu preferencia */
    padding: 0.5rem 1rem; /* Ajusta el relleno del botón según tu preferencia */
    cursor: pointer;
  }


  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    z-index: 1; /* Asegúrate de tener un índice z superior para que el menú se muestre sobre otros elementos */
  }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;

  }

  .nav-item {
    position: relative; /* Añade esta línea */

    color: #01B4BB;
  }
  .nav-item.dropdown-active {
    background-color: #000000; /* Cambia el color de fondo del botón del dropdown a negro */
    border-radius: 4px; /* Ajusta el radio del borde según tu preferencia */
    padding: 0.5rem 1rem; /* Ajusta el relleno del botón según tu preferencia */
    cursor: pointer;
  }



  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #01B4BB;
  }

  .nav-links:hover {
    background-color: #F6F7FB;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: relative;
    margin-top: 10px; /* Adjust this value to your preference */
  }

  .navbar-logo-image {
    max-width: 150px; /* Adjust the value according to your preference */
    margin-top: 0; /* Reset the top margin for smaller screens */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #01B4BB;
  }

  .fa-times {
    color: #F6F7FB;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #01B4BB; /* o el color que prefieras */
    padding: 14px 20px;
    border: 1px solid #01B4BB;
    transition: all 0.3s ease-out;
  }
  .dropdown-menu {
    bottom: 100%; /* Cambia el valor a bottom para que el menú aparezca arriba */
    top: auto; /* Ajusta la parte superior del menú */
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
  }

  .nav-links-mobile:hover {
    background: #F6F7FB;
    color: #242424;
    transition: 250ms;
  }


}

.HeroSection1_herocontainer__2cgmp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.HeroSection1_videosection__39rSn {
  position: relative;
}

.HeroSection1_videoContainer__Wpb5u {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.HeroSection1_h1hero1__10Uaw {
  color: #ffffff; /* Cambia el color del texto a blanco */
  font-size: 2.5rem; /* Cambia el tamaño del texto */
}
.HeroSection1_video1__3popZ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HeroSection1_textContainer__1qNXJ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .HeroSection1_herocontainer__2cgmp {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .HeroSection1_textContainer__1qNXJ {
    width: 80%;
  }
}

/* Ajuste para eliminar el espacio en blanco entre los videos */
@media screen and (min-width: 768px) {
  .HeroSection1_herocontainer__2cgmp {
    grid-column-gap: 0; /* Elimina el margen entre las columnas */
  }
}

.container1 {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/static/media/bg.15f80114.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 20px;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.container1 > * {
    flex: 1 1;
}


.box-info {
    color: #fff;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-left: 80px;
}

.box-info > h1 {
    text-align: left;
    letter-spacing: 5px;
}
.h1contact {
    text-align: left;
    letter-spacing: 5px;
    font-size: 36px;
}

.data {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px; /* Aumentado el espacio entre los elementos */
}

.data > p {
    font-size: 1.2rem; /* Aumentado el tamaño de fuente */
}

.data > p > i {
    color:#00A9BA;
    margin-right: 10px;
    font-size:25px;
}



.links {
    display:flex;
    align-items: center; /* Center items vertically */
    grid-gap:15px;
    gap:15px;
}

.links > a {
    text-decoration: none;
    width: 40px;
    height: 40px;
    background: #00A9BA;
    text-align: center;
    display: flex; /* Use flexbox to center the icon */
    align-items: center; /* Center icon vertically */
    justify-content: center; /* Center icon horizontally */
    transition: 0.1s;
}

.links > a > i {
    color: #fff;
    font-size: 20px;
}

form {
    display:flex;
    flex-direction: column;
    text-align: center;
    grid-gap:15px;
    gap:15px;
}

.input-box {
    position:relative;
}

.input-box > input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    outline:none;
    background: rgba(255 255 255 / .1);
    border:3px solid transparent;
    letter-spacing: 1px;
    transition:.3s;
    color:#fff;

}

.input-box > input::placeholder,
.input-box > textarea::placeholder {
    color:#a3a3a3;

}

.input-box > input:focus::placeholder,
.input-box > textarea:focus::placeholder {
    color:transparent;
}

.input-box > input:focus,
.input-box > textarea:focus {
    border-bottom:3px solid #00A9BA;
    animation: shake .2s;
}

.input-box > textarea {
    width: 100%;
    height: 130px;
    padding: 10px;
    background: rgba(255 255 255 / .1);
    border:3px solid transparent;
    letter-spacing: 1px;
    outline: none;
    transition:.3s;
    color:#fff;
    letter-spacing: 1.5px;
}


.input-box > i {
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    right: 10px;
    color:rgba(255 255 255 / .3);
    transition: .3s;
}

.input-box > input:focus ~ i {
    color:#00A9BA;
}

form > button {
    width: 100%;
    padding: 10px;
    outline: none;
    background: #00A9BA;
    color:#fff;
    border:none;
    transition: .1s;
    cursor: pointer;
    font-size: 1rem;
}

form > button:hover,
.links > a:hover {
    background: rgb(255, 255, 255);
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Fondo blanco semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


@keyframes shake {
    0%, 100% {transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
    20%, 40%, 60%, 80% {transform: translateX(10px);}
 }


@media screen and (max-width:600px) {
    .container {
        width: 95%;
        display: flex;
        flex-direction: column;
        grid-gap:20px;
        gap:20px;
    }
    form {
        width: 100%;
    }
    .container1 {
        flex-direction: column;
        align-items: center;
    }

    .box-info {
        color: #fff;
        display: flex;
        flex-direction: column;
        grid-gap: 50px;
        gap: 50px;
        margin-left: 0;
    }

    .h1contact {
        text-align: left;
        letter-spacing: 5px;
        font-size: 30px;
    }
}



.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Alinea el texto al centro */
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-around; /* Espacia los elementos del footer */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en dispositivos pequeños */
}


.li-footer{

  margin-bottom: 8px;
  color: white;
  cursor: pointer;
}
.p-correo {
  color:white;
  cursor: pointer;
}

.youtube-icon {
  color: red; /* Cambia el color del icono de YouTube a rojo */
}

.linkedin-icon {
  color: blue; /* Cambia el color del icono de LinkedIn a azul */
}

.footer-link-wrapper {
  display: flex;
  flex-direction: column; /* Apila los elementos del footer en columnas */
  align-items: center; /* Alinea los elementos al centro */
  margin: 10px;
  text-align: center; /* Alinea el texto al centro */
  width: 220px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 8px;
  color: #01B4BB; /* Color del título */
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.social-media-wrap {
  display: flex;
  justify-content: space-around; /* Espacia los elementos del footer */
  align-items: center; /* Alinea los elementos al centro */
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-around; /* Espacia los iconos sociales */
  align-items: center; /* Alinea los iconos al centro */
  width: 240px;
}

.social-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

/* Estilo específico para los iconos de LinkedIn y YouTube */
.social-icon-link {
  font-size: 1.7rem; /* Tamaño de los iconos */
  margin-bottom: 1rem; /* Espaciado entre los iconos y el texto */
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column; /* Apila los elementos del footer en columnas */
    width: 100%; /* Ajusta el ancho al 100% */
    margin-bottom: 20px; /* Agrega espaciado entre los elementos */
  }

  .social-media-wrap {
    flex-direction: column; /* Apila los iconos sociales en columnas */
  }
}
